import axiosInstance from "./axiosInstance";

//新增栏目
export function create(param) {
	return axiosInstance.post('channel/addChannel', param)
}
//编辑栏目
export function update(param) {
	return axiosInstance.post('channel/editChannel', param)
}
//删除栏目
export function del(param) {
	return axiosInstance.post('channel/deleteChannel', param)
}
//分页查询栏目列表
export function query(param) {
	return axiosInstance.post('channel/queryChannelList', param)
}
//获取树形栏目
export function tree() {
	return axiosInstance.post('channel/queryTreeChannel')
}
export function upload(formData) {
	return axiosInstance.post('file/obsUploadFile', formData)
}
