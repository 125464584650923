import axiosInstance from "./axiosInstance";

export function create(param) {
  return axiosInstance.post('scenic/addArticle',param)
}

export function update(param) {
  return axiosInstance.post('scenic/editArticle',param)
}

export function del(param) {
	return axiosInstance.post('scenic/deleteArticle', param)
}

export function query(param) {
  return axiosInstance.post('scenic/queryArticleList',param)
}

export function uploadImage(formData) {
	return axiosInstance.post('file/obsUploadFile', formData)
}
export function uploadVideo(formData) {
	return axiosInstance.post('file/obsUploadVideo', formData)
}